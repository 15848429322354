import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'select-car',
    loadChildren: () => import('./pages/select-car/select-car.module').then(m => m.SelectCarPageModule)
  },
  {
    path: 'select-round-instruction',
    loadChildren: () => import('./pages/select-round-instruction/select-round-instruction.module').then(m => m.SelectRoundInstructionPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'site-list-navigation',
    loadChildren: () => import('./pages/site-list-navigation/site-list-navigation.module').then(m => m.SiteListNavigationPageModule)
  },
  {
    path: 'dash-on-site',
    loadChildren: () => import('./pages/dash-on-site/dash-on-site.module').then(m => m.DashOnSitePageModule)
  },
  {
    path: 'cargaison',
    loadChildren: () => import('./pages/cargaison/cargaison.module').then(m => m.CargaisonPageModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stock/stock.module').then(m => m.StockPageModule)
  },
  {
    path: 'pick-up-full',
    loadChildren: () => import('./pages/pick-up-full/pick-up-full.module').then(m => m.PickUpFullPageModule)
  },
  {
    path: 'pick-up-empty',
    loadChildren: () => import('./pages/pick-up-empty/pick-up-empty.module').then(m => m.PickUpEmptyPageModule)
  },
  {
    path: 'delivery-full',
    loadChildren: () => import('./pages/delivery-full/delivery-full.module').then(m => m.DeliveryFullPageModule)
  },
  {
    path: 'delivery-empty',
    loadChildren: () => import('./pages/delivery-empty/delivery-empty.module').then(m => m.DeliveryEmptyPageModule)
  },
  {
    path: 'modal-select-car',
    loadChildren: () => import('./pages/modal-select-car/modal-select-car.module').then(m => m.ModalSelectCarPageModule)
  },
  {
    path: 'resume-round',
    loadChildren: () => import('./pages/resume-round/resume-round.module').then( m => m.ResumeRoundPageModule)
  },
  {
    path: 'show-delivery',
    loadChildren: () => import('./pages/show-delivery/show-delivery.module').then( m => m.ShowDeliveryPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
