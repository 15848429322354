import {Component, OnInit} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {AuthenticationService} from './gateways/services/authentication.service';
import {StatusConnexionEnum} from './entity/enum/status-connexion-enum';
import {RoundService} from './gateways/services/round/round.service';
import {NetworkService} from "./gateways/services/network.service";


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public roundId = null;
    public appPages = [
        {
            title: 'entrer sur un site',
            url: '/site-list-navigation',
            icon: 'enter'
        },
        // {
        //     title: 'Résumé de la tournée',
        //     url: '/resume-round',
        //     icon: 'list'
        // },
        // {
        //     title: 'Liaison',
        //     url: '/select-car',
        //     icon: 'git-merge'
        // },
        // {
        //     title: 'Guide d\'utilisation',
        //     url: '/select-car',
        //     icon: 'information'
        // }
    ];


    constructor(
        private roundService: RoundService,
        private authenticationService: AuthenticationService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public alertController: AlertController,
        private router: Router,
        private networkService: NetworkService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }
    }

    goToStock() {
        if (this.networkService.networkIsConnected) {
            this.router.navigate(['/stock'], {replaceUrl: true, queryParams: {backUrl: '/dashboard'}});
        } else {
            alert('Aucune connexion, veuillez vous reconnecter.');
        }
    }

    async finishRound() {
        if (this.networkService.networkIsConnected) {
            const ok = confirm('Etes vous sur de vouloir fermer la tournée ?');
            if (ok == true) {
                this.roundService.closeRound()
                    .subscribe(value => {
                            return this.deconnexion();
                        },
                        (error) => {
                            console.log('Error occurred! : ' + error);
                        });
            } else {
                return;
            }
        } else {
            alert('Aucune connexion, veuillez vous reconnecter.');
        }
    }

    deconnexion() {
        localStorage.removeItem('listRequestOffline');
        localStorage.removeItem('roundInstructionId');
        localStorage.removeItem('id_token');
        localStorage.removeItem('carId');
        this.authenticationService.isConnected$.next(StatusConnexionEnum.notYet);
        this.router.navigate(['/login'], {replaceUrl: true});
    }

    alertConnexion() {
        if (this.networkService.networkIsConnected == false) {
            alert('Aucune connexion, veuillez vous reconnecter.');
        }
    }
}
