import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RootService} from "../root.service";
import {Observable} from "rxjs";
import {ContainerTypeDto} from "../../dtos/containerTypes/container-type-dto";

@Injectable({
	providedIn: 'root'
})
export class ContainerTypeService extends RootService {
	
	constructor(public http: HttpClient) {
		super(http);
	}
	
	getAllContainerTypes(): Observable<Array<ContainerTypeDto>> {
		return this.http.get<Array<ContainerTypeDto>>(this.URL + 'container-types', {});
	}
}
