import {ErrorEnum} from './ErrorEnum';
import {Injectable} from '@angular/core';

export interface Error {
    key: ErrorEnum;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private errors: Array<Error> = [];

    constructor() {
        this.errors.push({key: ErrorEnum.error_no_network_available, value : 'Error, pas de connexion internet' });

        this.errors.push({key: ErrorEnum.error_round_creation, value : 'Error lors de la création de la tournée' });
        this.errors.push({key: ErrorEnum.error_round_select, value: 'Error lors de la selection de la tournée'});
        this.errors.push({key: ErrorEnum.error_round_end, value: 'Error lors de la fermeture de la tournée'});

        this.errors.push({key: ErrorEnum.error_cars_created, value: 'Error lors de la création du véhicule'});

        this.errors.push({key: ErrorEnum.error_users_login, value: 'IDENTIFIANT OU MOT DE PASS INCORRECT'});
        this.errors.push({key: ErrorEnum.error_users_logout, value: 'Error lors de la déconnexion de l utilisateur'});

        this.errors.push({key: ErrorEnum.error_checkpoint_created, value: 'Error lors de la creation du checkpoint'});
        this.errors.push({key: ErrorEnum.error_checkpoint_select, value: 'Error lors de la selection du checkpoint'});
        this.errors.push({key: ErrorEnum.error_checkpoint_end, value: 'Error lors de la fermeture du checkpoint'});
    }

    getMessage(error: ErrorEnum): string {
        return this.errors.filter( value => value.key === error )[0].value;
    }
}
