import {Injectable} from '@angular/core';
import {RootService} from './root.service';
import {HttpClient} from '@angular/common/http';
import {StatusConnexionEnum} from '../../entity/enum/status-connexion-enum';
import {ErrorService} from '../dtos/errors/Errors';
import {ErrorEnum} from '../dtos/errors/ErrorEnum';
import {ToastController} from '@ionic/angular';
import jwt_decode from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends RootService {

    constructor(
        public toastController: ToastController,
        public http: HttpClient,
        public errorService: ErrorService
    ) {
        super(http);
    }

    login(login: string, pwd: string) {
        return this.http.post<TokenDto>(this.URL + 'authenticate', {
            password: pwd,
            rememberMe: false,
            username: login
        }).subscribe(value => {

            this.roleCheck(value.id_token)
                .then((resVerifRole) => {
                    localStorage.removeItem('id_token');
                    localStorage.setItem('id_token', value.id_token);
                    this.isConnected$.next(StatusConnexionEnum.connected);
                })
                .catch((errVerifRole) => {
                    alert(errVerifRole);
                    this.isConnected$.next(StatusConnexionEnum.refused);
                });
        }, error => {

            this.isConnected$.next(StatusConnexionEnum.refused);
            // @ts-ignore
            if (this.isConnected$ !== StatusConnexionEnum.connected) {
                this.presentToast(this.errorService.getMessage(ErrorEnum.error_users_login));
            }
        });
    }

    roleCheck(token){
        const decodedToken: TokenDecodedDto = jwt_decode(token);
        const roles = decodedToken.auth.split(',');
        let countTrue = 0;
        return new Promise((res, rej) => {
            for (const role of roles){
                switch (role) {
                    case 'ROLE_GLOBAL_MANAGER' : countTrue++; break;
                    case 'ROLE_MANAGER' : break;
                    case 'ROLE_DRIVER' :  countTrue++; break;
                    case 'ROLE_VIEWER' :  break;
                    case 'ROLE_PICK_UP' :  break;
                    case 'ROLE_DRIVER_MANAGER' : break;
                    case 'ROLE_LABORATORY_MANAGER' : break;
                }
            }

            if (countTrue > 0){
                res(true);
            } else{
                rej('Vous n\'avez pas les droits nécessaires');
            }
        });
    }

    async presentToast(message: string) {
        const toast = await this.toastController.create({ message, duration: 5000 });
        await toast.present();
    }
}
