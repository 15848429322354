import { Pipe, PipeTransform } from '@angular/core';
import {SiteDto} from "../../gateways/dtos/site/site-dto";

@Pipe({
  name: 'getSite'
})
export class GetSitePipe implements PipeTransform {

  transform(siteId: number, listSite: Array<SiteDto>){

    const findSite = listSite.findIndex(x => x.id == siteId);

    if(findSite != -1){
      return listSite[findSite].name;
    } else{
      return null;
    }

  }

}
