import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RootService} from '../root.service';
import {DashboardDto} from '../../dtos/dashboard/dashboard-dto';
import {RoundDto} from '../../dtos/round/RoundDto';
import {RoundCheckPointDetailsDto} from '../../dtos/roundCheckPoint/round-check-point-details-dto';
import {BehaviorSubject} from "rxjs";
import {ContainerTypeDto} from "../../dtos/containerTypes/container-type-dto";
import {ContainerTypeService} from "../containerTypeService/container-type.service";

@Injectable({
    providedIn: 'root'
})
export class RoundService extends RootService {
    
    public containerTypes$: BehaviorSubject<ContainerTypeDto[]> = new BehaviorSubject<ContainerTypeDto[]>([]);

    constructor(
        public http: HttpClient,
        public containerTypeService: ContainerTypeService
    ) {
        super(http);
    }

    startOrContinueRound(roundInstructionId: number, date: string, carId: number) {
        this.containerTypeService.getAllContainerTypes()
            .subscribe(containerTypes => {this.containerTypes$.next(containerTypes)});
        
        const endUrl = 'rounds/roundInstruction/' + roundInstructionId + '/date/' + date + '/carId/' + carId;
        return this.http.post<DashboardDto>(this.URL + endUrl, {})
            .subscribe(value => {
                value.previousRoundCheckPoints = value.previousRoundCheckPoints ? this.sortRoundCheckPointLight(value.previousRoundCheckPoints) : [];
                value.nextRoundCheckPoints = value.nextRoundCheckPoints ? this.sortRoundCheckPointDetail(value.nextRoundCheckPoints) : [];
                this.dashBoardData$.next(value);
            });
    }

    sortRoundCheckPointLight(listToSort: Array<RoundCheckPointDetailsDto>): Array<RoundCheckPointDetailsDto> {
        let sortedList: Array<RoundCheckPointDetailsDto>;
        sortedList = listToSort.sort((a, b) => a.roundCheckPoint.plannedAt < b.roundCheckPoint.plannedAt ? -1 : 1);
        return sortedList;
    }

    sortRoundCheckPointDetail(listToSort: Array<RoundCheckPointDetailsDto>): Array<RoundCheckPointDetailsDto> {
        let sortedList: Array<RoundCheckPointDetailsDto>;
        sortedList = listToSort.sort((a, b) => a.roundCheckPoint.plannedAt > b.roundCheckPoint.plannedAt ? 1 : -1);
        return sortedList;
    }

    closeRound() {
        const roundId = this.dashBoardData$.getValue().roundId;
        const endUrl = 'rounds/close/roundId/' + roundId;
        return this.http.put<RoundDto>(this.URL + endUrl, {});
    }
}
