import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {StatusConnexionEnum} from '../../entity/enum/status-connexion-enum';
import {DashboardDto} from "../dtos/dashboard/dashboard-dto";
import {ContainerCargaisonDto} from "../dtos/containers/container-cargaison-dto";



@Injectable({
  providedIn: 'root'
})
export class RootService {

  public URL: string;
  public isConnected$ = new BehaviorSubject<StatusConnexionEnum>(StatusConnexionEnum.notYet);
  public dashBoardData$ = new BehaviorSubject<DashboardDto>(null);
  public cargaisonReformat$ = new BehaviorSubject<{full , empty }>(null);

  constructor(public http: HttpClient) {
    this.URL = environment.back_url;
  }
}
