export enum ErrorEnum {
    error_no_network_available = 'ERROR_NO_NETWORK_AVAILABLE',

    error_cars_created = 'ERROR_ROUND_CREATED',

    error_users_login = 'ERROR_USERS_LOGIN',
    error_users_logout = 'ERROR_USERS_LOGOUT',

    error_checkpoint_created = 'ERROR_CHECKPOINT_CREATION',
    error_checkpoint_select = 'ERROR_CHECKPOINT_SELECT',
    error_checkpoint_end = 'ERROR_CHECKPOINT_END',

    error_round_creation = 'ERROR_ROUND_CREATED',
    error_round_select = 'ERROR_ROUND_SELECT',
    error_round_end = 'ERROR_ROUND_END',
}
