import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SDHttpInterceptor} from './gateways/services/SDHttpInterceptor';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx/';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';

import {Network} from '@ionic-native/network/ngx';
import { GetSitePipe } from './pipes/site/get-site.pipe';

@NgModule({
  declarations: [AppComponent, GetSitePipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    LaunchNavigator,
    Geolocation,
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: SDHttpInterceptor, multi: true},
    HttpClientModule,
    AndroidPermissions,
    BarcodeScanner,
    Network
  ],
  exports: [
    GetSitePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
