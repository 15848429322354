import {Injectable, NgZone} from '@angular/core';
import {Network} from "@ionic-native/network/ngx";
import {Subscription} from "rxjs";
import { ConnectionService } from 'ng-connection-service';
import {AlertController, Platform} from "@ionic/angular";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    public networkIsConnected: boolean = false;
    private disconnectSubscription: Subscription;
    private connectSubscription: Subscription;
    private desktopNetworkMonitorSubscription: Subscription;
    public lastStateOfConnexion : boolean = false;

    constructor(
        private network: Network,
        private connectionService: ConnectionService,
        private platform: Platform,
        public zone: NgZone,
        public alertController: AlertController,
        private router: Router
    ) {
        if(this.platform.is("cordova") == false && this.platform.is("capacitor") == false) {
            this.networkIsConnected = true;
        }
    }

    loadService() {
        let message : string;

        this.networkIsConnected = navigator.onLine;
        if(this.platform.is('cordova')) {
            // watch network for a disconnection
            this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
                this.zone.run(()=>{
                    this.lastStateOfConnexion = this.networkIsConnected;
                    this.networkIsConnected = false;
                })
                this.presentAlert('Vous êtes actuellement hors ligne.', null);
            });

            // watch network for a connection
            this.connectSubscription = this.network.onConnect().subscribe(() => {
                this.zone.run(()=>{
                    this.lastStateOfConnexion = this.networkIsConnected;
                    this.networkIsConnected = true;
                })

                if(this.lastStateOfConnexion != this.networkIsConnected){

                    if(this.router.url == "/dash-on-site"){
                        message = "Vos données seront mis à jour automatiquement à la sortie du site."
                    }
                    if (this.router.url.search("/dashboard") != -1 ){
                        message = "Pour mettre à jour vos données, veuillez glisser votre doigt de haut en bas."
                    }
                    this.presentAlert('Vous êtes à nouveau connecté.', message)
                }
            });
        }
        else {
            this.desktopNetworkMonitorSubscription = this.connectionService.monitor().subscribe((isConnected: boolean) => {
                this.networkIsConnected = isConnected;
            });
        }
    }

    unsubscribeNetworkService() {
        if(this.platform.is('cordova')) {
            this.disconnectSubscription.unsubscribe();
            this.connectSubscription.unsubscribe();
        }
        else {
            this.desktopNetworkMonitorSubscription.unsubscribe();
        }
    }

    async presentAlert(subheader : string, message : string) {
        const alert = await this.alertController.create({
            header: 'Connexion',
            subHeader : subheader,
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }
}
